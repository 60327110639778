
export enum AuctionState {
  Draft= 'Draft',
  ToBeApproved= 'ToBeApproved',
  ToBeScheduled= 'ToBeScheduled',
  Waiting= 'Waiting',
  Running= 'Running',
  Pending= 'Pending',
  Expired= 'Expired',
  Canceled= 'Canceled',

}